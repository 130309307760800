<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 21 }"
    >
      <a-form-model-item label="渠道类型" prop="channelTypeId">
        <a-select placeholder="请选择" v-model="rowData.channelTypeId">
          <a-select-option :value="item.id" v-for="(item, index) in channelTypeList" :key="index">{{
            item.title
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="姓名" prop="customerName">
        <a-input v-model="rowData.customerName" placeholder="客户姓名"></a-input>
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="mobile">
        <a-input v-model="rowData.mobile" placeholder="手机号"></a-input>
      </a-form-model-item>
      <a-form-model-item label="公司名称" prop="companyName">
        <a-input v-model="rowData.companyName" placeholder="公司名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="客户等级" prop="customerLevelId">
        <a-select placeholder="请选择" v-model="rowData.customerLevelId">
          <a-select-option :value="item.id" v-for="(item, index) in customerLevelList" :key="index">{{
            item.title
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属门店" prop="storeId" :autoLink="false" ref="item">
        <a-select
          placeholder="请选择"
          v-model="rowData.storeId"
          :disabled="handle === 'check' ? true : false"
          @change="onStoreChange"
        >
          <a-select-option :value="item.id" v-for="(item, index) in followerList" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属员工" prop="managerId">
        <a-select placeholder="请选择" v-model="rowData.managerId" :disabled="handle === 'check' ? true : false">
          <a-select-option :value="item.id" v-for="(item, index) in managerList" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="生日" prop="birthday">
        <DatePicker :startTime.sync="rowData.birthday"></DatePicker>
      </a-form-model-item>
      <a-form-model-item label="兴趣爱好" prop="hobby">
        <a-input v-model="rowData.hobby" placeholder="请输入爱好"></a-input>
      </a-form-model-item>
      <a-form-model-item label="性别" prop="gender">
        <a-radio-group v-model="rowData.gender">
          <a-radio :value="0"> 男 </a-radio>
          <a-radio :value="1"> 女 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="所在区域" prop="provinceId">
        <PCD
          ref="pcd"
          placeholder="请选择区域"
          :province.sync="rowData.provinceName"
          :city.sync="rowData.cityName"
          :district.sync="rowData.areaName"
          :provinceId.sync="rowData.provinceId"
          :cityId.sync="rowData.cityId"
          :districtId.sync="rowData.areaId"
          :disabled="handle === 'add' ? false : true"
        >
        </PCD>
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="">
        <!-- <div style="display: flex;"> -->
        <a-input-group>
          <a-textarea
            v-model="rowData.address"
            :disabled="handle === 'add' ? false : true"
            :auto-size="{ minRows: 3, maxRows: 21 }"
            placeholder="请输入详细地址"
          ></a-textarea>
<!--          <a-input-->
<!--            v-model="rowData.building"-->
<!--            :disabled="handle === 'add' ? false : true"-->
<!--            style="width: 80px; margin-left: 20px"-->
<!--            addon-after="栋"-->
<!--          ></a-input>-->
<!--          <a-input-->
<!--            v-model="rowData.unit"-->
<!--            :disabled="handle === 'add' ? false : true"-->
<!--            style="width: 80px; margin-left: 20px"-->
<!--            addon-after="单元"-->
<!--          ></a-input>-->
<!--          <a-input-->
<!--            v-model="rowData.room"-->
<!--            :disabled="handle === 'add' ? false : true"-->
<!--            style="width: 80px; margin-left: 20px"-->
<!--            addon-after="号"-->
<!--          ></a-input>-->
        </a-input-group>
        <!-- </div> -->
      </a-form-model-item>
      <a-form-model-item label="备注" prop="(remark">
        <a-textarea v-model="rowData.remark" placeholder="请输入" :auto-size="{ minRows: 3, maxRows: 21 }" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  editDealerCustomerSource,
  selectByIdDealerCustomerSource,
  addDealerCustomerSource,
} from '../api/DealerCustomerSourceApi'
export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        customerName: [{ required: true, message: '请输入客户姓名', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        companyName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }],
        customerLevelId: [{ required: true, message: '请选择客户等级', trigger: 'change' }],
        //provinceId: [{ required: true, message: '请选择练习地址', trigger: 'change' }],
        channelTypeId: [{ required: true, message: '请选择渠道类型', trigger: 'cahnge' }],
        storeId: [{ required: true, message: '请选择门店', trigger: 'blur' }],
        managerId: [{ required: true, message: '请选择员工', trigger: 'blur' }],
      },
      channelTypeList: [],
      customerLevelList: [],
      managerList: [],
      followerList: [],
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.axios.get('/api/base/system/dictionary/selectByCode/客户渠道类型').then((res) => {
        // 客户渠道类型
        this.channelTypeList = res.body
      })
      this.axios.get('/api/base/system/dictionary/selectByCode/客户等级').then((res) => {
        // 客户等级
        this.customerLevelList = res.body
      })

      if (handle === 'edit') {
        selectByIdDealerCustomerSource(row.id).then((res) => {
          this.getManager(res.body.storeId)
          this.rowData = res.body
          if (res.body) {
            this.$set(this.rowData, 'provinceId', res.body.provinceId)
            this.$set(this.rowData, 'cityId', res.body.cityId)
            this.$set(this.rowData, 'countyId', res.body.countyId)
            this.$set(this.rowData, 'address', res.body.address)
            this.$set(this.rowData, 'storeId', res.body.storeId)
            this.$nextTick(() => {
              this.$refs.pcd.setPCD()
            })
          }
        })
      }
      // 获取门店
      this.axios.get(`/api/base/system/dealer/manager/getMinePower?flagPersonData=false`).then((res) => {
        this.followerList = res.body.powerVOList
      })
    },

    // 获取员工
    getManager(id) {
      this.axios.get(`/api/base/achievements/dealerAchievements/manager/choose?storeId=${id}`).then((res) => {
        this.managerList = res.body
      })
    },

    // 根据门店id获取该门店下的员工
    onStoreChange(val) {
      this.$refs.item.onFieldChange()
      this.getManager(val)
    },

    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        const res =
          this.handle === 'add'
            ? await addDealerCustomerSource(this.rowData)
            : await editDealerCustomerSource(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
</style>
