/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-15 10:47:11
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-17 09:28:48
 */
export const columns = [
{
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 50,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'index'}
},
{
  title: '所属门店',
  dataIndex: 'storeName',
  key: 'storeName',
  width:150,
  align:'center',
},
{
  title: '所属员工',
  dataIndex: 'managerName',
  key: 'managerName',
  width:150,
  align:'center',
},
{
    title: '姓名',
    dataIndex: 'customerName',
    key: 'customerName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',
    align:'center',
    ellipsis:true
},
{
    title: '公司名称',
    dataIndex: 'companyName',
    key: 'companyName',
    align:'center',
    ellipsis:true
},
{
    title: '渠道类型',
    dataIndex: 'channelType',
    key: 'channelType',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '客户等级',
    dataIndex: 'customerLevel',
    key: 'customerLevel',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '联系地址',
    dataIndex: 'address',
    key: 'address',
  width: 250,
    align:'center',
    ellipsis:true,
    scoprdSlots: { customRender: 'address'}

},
{
    title: '操作时间',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    // width: 150,
    align: 'center',
    ellipsis: true
},
// {
//     title: '操作',
//     dataIndex: 'action',
//     key: 'action',
//     width: 150,
//     align: 'center',
//     ellipsis: true
// },
]
