<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="客户名">
              <a-input v-model="searchData.search" placeholder="客户名/手机号/公司名" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="客户等级">
              <a-select
                placeholder="请选择"
                style="width: 120px"
                v-model="searchData.customerLevelId"
                allowClear
              >
                <a-select-option :value="item.id" v-for="(item, index) in cusTypeData" :key="index">
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="4" :sm="12">
            <a-form-model-item label="渠道类型">
                <a-select
                placeholder="请选择"
                style="width: 120px"
                v-model="searchData.channelTypeId"
                allowClear
              >
                <a-select-option :value="item.id" v-for="(item, index) in qudaoTypeData" :key="index">
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button v-if="addEnable" type="primary" @click="toHandler('add')">新增</a-button>
        <a-button v-if="editEnabel && selectedRows.length == 1" type="primary" @click="toHandler('edit')">修改</a-button>
        <a-button v-if="isTransfer" type="primary" @click="handOver">移交</a-button>
        <a-button v-if="removeEnable && selectedRows.length == 1" type="danger" @click="toHandler('del')">删除</a-button>
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
        <span slot="address" slot-scope="text, row">{{ row.address }}</span>
      </a-table>
      <!-- 编辑表单 -->
      <DealerCustomerSource-edit-modal
        ref="DealerCustomerSourceEditModal"
        @reload="getData"
      ></DealerCustomerSource-edit-modal>

      <!-- 选择移交员工 -->
      <CheckStaffPage ref="CheckStaffPage" @reload="getData"></CheckStaffPage>
    </div>
  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerCustomerSourceEditModal from './components/DealerCustomerSourceEditModal.vue'
import {
  delDealerCustomerSource,
  listDealerCustomerSource,
  selectByIdDealerCustomerSource,
} from './api/DealerCustomerSourceApi'
import { checkPermission } from '@/utils/permissions'
import CheckStaffPage from './components/CheckStaffPage.vue'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerCustomerSource',
  components: {
    CheckStaffPage,
    DealerCustomerSourceEditModal,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      addEnable: checkPermission('dealer:customer_source:add'),
      editEnabel: checkPermission('dealer:customer_source:edit'),
      removeEnable: checkPermission('dealer:customer_source:remove'),
      isTransfer: checkPermission('dealer:customer_source:transfer'),
      cusTypeData: [],
      qudaoTypeData: [],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      listDealerCustomerSource({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
      this.axios.get(`/api/base/system/dictionary/selectByCode/客户渠道类型`).then((res) => {
        if (res.code == 200) {
          this.qudaoTypeData = res.body
        }
      })
      this.axios.get(`/api/base/system/dictionary/selectByCode/客户等级`).then((res) => {
        if (res.code == 200) {
          this.cusTypeData = res.body
        }
      })
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    // 移交
    handOver() {
      if(this.selectedRowKeys.length <= 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      this.$refs.CheckStaffPage.setRowData(this.selectedRowKeys, 'hand_over_cus')
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      // this.page = Object.assign({}, this.page)
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerCustomerSourceEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerCustomerSourceEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerCustomerSource(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
